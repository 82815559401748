import React from 'react';
import PropTypes from 'prop-types';

import styles from '@styles/TripTypePicker.module.css';

// 1: Oneway 2: Return
export const TripType = {
    Oneway: 1,
    RoundTrip: 2,
};

export const TripTypeName = (code) => {
    switch (code) {
        case TripType.Oneway:
            return '单程';
        case TripType.RoundTrip:
            return '往返';
        default:
            return '';
    }
};

function TripTypePicker({ tripType = TripType.Oneway, setTripType = () => {}, callback = () => {} }) {
    const handleOnClick = (v) => {
        setTripType(v);
        callback();
    };
    return (
        <div className={styles.container}>
            {[TripType.Oneway, TripType.RoundTrip].map((item, index) => {
                return (
                    <div
                        className={styles.item}
                        key={index}
                        // aria-selected={item == tripType}
                        onClick={() => handleOnClick(item)}
                    >
                        {TripTypeName(item)}
                    </div>
                );
            })}
        </div>
    );
}

TripTypePicker.propTypes = {
    callback: PropTypes.func.isRequired,
    tripType: PropTypes.number,
    setTripType: PropTypes.func.isRequired,
};

export default TripTypePicker;
