import { GetToken } from './utils/storage';
import * as config from '@config';

class WebSocketManager {
    constructor(url, token) {
        this.ws = null;
        this.url = url;
        this.token = token;
        this.reconnectInterval = 1000; // Initial reconnect interval in ms
        this.maxReconnectInterval = 10_000; // Maximum reconnect interval in ms
        this.shouldReconnect = true; // Control variable for reconnection attempts
        this.messageListeners = [];
        this.connect();
    }

    //NOTE: Not in use
    async authenticate() {
        const response = await fetch('/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username: 'user', password: 'pass' }),
        });

        if (!response.ok) {
            console.error('Failed to authenticate');
            return null;
        }

        const data = await response.json();
        return data.token;
    }

    async connect() {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            console.log('WebSocket connection already open');
            return;
        }

        // NOTE: No need now
        // const token = await this.authenticate();
        // if (!token) {
        //     console.error('Authentication failed');
        //     return;
        // }

        this.ws = new WebSocket(`${this.url}?token=${this.token}`);

        this.ws.onopen = () => {
            console.log('Connected to the server');
            this.reconnectInterval = 1000; // Reset reconnect interval on successful connection
        };

        this.ws.onmessage = (event) => {
            console.log('Received data:', event.data);
            let data = event.data;
            this.messageListeners.forEach((listener) => listener(data));
        };

        this.ws.onclose = (event) => {
            console.log('Disconnected from the server', event.reason);
            if (this.shouldReconnect) {
                this.attemptReconnect();
            }
        };

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.ws.close(); // Close the WebSocket on error to trigger reconnection
        };
    }

    attemptReconnect() {
        setTimeout(() => {
            console.log('Attempting to reconnect...');
            this.connect();
            if (this.reconnectInterval < this.maxReconnectInterval) this.reconnectInterval *= 2; // Exponential backoff
        }, this.reconnectInterval);
    }

    sendMessage(message) {
        if (message && this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(message);
        } else {
            console.error('Cannot send message: WebSocket is not open');
        }
    }

    disconnect() {
        this.shouldReconnect = false; // Prevent reconnection attempts
        if (this.ws) {
            this.ws.close(); // Close the WebSocket connection
        }
        console.log('Disconnected from the server by the user');
    }

    addMessageListener(listener) {
        this.messageListeners.push(listener);
    }

    removeMessageListener(listener) {
        this.messageListeners = this.messageListeners.filter((l) => l !== listener);
    }

    isConnected() {
        return this.ws && this.ws.readyState === WebSocket.OPEN;
    }
}

export const PubSubDataType = {
    CMD: 'CMD',
    MSG: 'MSG',
};

export const PubSubCMD = {
    REFRESH_COMMENTS: 'refresh_comments',
};

function isJSONObject(data) {
    if (typeof data !== 'string') {
        return false; // JSON.parse accepts only strings
    }
    try {
        const jsonObject = JSON.parse(data);
        return typeof jsonObject === 'object' && jsonObject !== null;
    } catch (e) {
        return false;
    }
}

// Singleton instance
let wsManagerInstance = null;

export function getWsManagerInstance() {
    if (!wsManagerInstance) {
        wsManagerInstance = new WebSocketManager(config.wsURL, GetToken());
    }
    return wsManagerInstance;
}
