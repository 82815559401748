import React from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/PassengerPicker.module.css';
import { MdOutlineAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';

//Only activity has family option
function PassengerPicker({
    numAdult = 0,
    setNumAdult = () => {},
    numChild = 0,
    setNumChild = () => {},
    hasFamilyPlan = false,
    numFamily = 0,
    setNumFamily = () => {},
    callback = () => {},
    maxNum,
}) {
    const addAdult = () => {
        if (maxNumCheck() == false) return;
        setNumAdult(numAdult + 1);
    };

    const minusAdult = () => {
        if (numAdult > 0) {
            setNumAdult(numAdult - 1);
        }
    };

    const addChild = () => {
        if (maxNumCheck() == false) return;
        setNumChild(numChild + 1);
    };

    const minusChild = () => {
        if (numChild > 0) {
            setNumChild(numChild - 1);
        }
    };

    const addFamily = () => {
        setNumFamily(numFamily + 1);
    };

    const minusFamily = () => {
        if (numFamily > 0) {
            setNumFamily(numFamily - 1);
        }
    };

    const maxNumCheck = () => {
        if (!!maxNum) {
            if (numAdult + numChild >= maxNum) {
                return false;
            }
        }
        return true;
    };

    return (
        <div className={styles.container}>
            <div className={styles.adult_container}>
                <div>成人(大于12岁)</div>
                <div className={styles.controller_container}>
                    <MdRemoveCircleOutline className={styles.icon} onClick={minusAdult} />
                    <span className={styles.number}>{numAdult}</span>
                    <MdOutlineAddCircleOutline className={styles.icon} onClick={addAdult} />
                </div>
            </div>
            <div className={styles.child_container}>
                <span>儿童(2岁~12岁)</span>
                <div className={styles.controller_container}>
                    <MdRemoveCircleOutline className={styles.icon} onClick={minusChild} />
                    <span className={styles.number}>{numChild}</span>
                    <MdOutlineAddCircleOutline className={styles.icon} onClick={addChild} />
                </div>
            </div>
            {hasFamilyPlan && (
                <div className={styles.family_container}>
                    <span>家庭票</span>
                    <div className={styles.controller_container}>
                        <MdRemoveCircleOutline className={styles.icon} onClick={minusFamily} />
                        <span className={styles.number}>{numFamily}</span>
                        <MdOutlineAddCircleOutline className={styles.icon} onClick={addFamily} />
                    </div>
                </div>
            )}
            <div className={styles.action_container}>
                <button className="primary_btn" onClick={() => callback()}>
                    确定
                </button>
            </div>
        </div>
    );
}

PassengerPicker.propTypes = {
    numAdult: PropTypes.number.isRequired,
    setNumAdult: PropTypes.func.isRequired,
    numChild: PropTypes.number.isRequired,
    setNumChild: PropTypes.func.isRequired,
    hasFamilyPlan: PropTypes.bool,
    numFamily: PropTypes.number,
    setNumFamily: PropTypes.func,
    callback: PropTypes.func,
    maxNum: PropTypes.number,
};

export default PassengerPicker;
