import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './features/account/accountSlice';
import messagesReducer from './features/messages/messagesSlice';
import activitiesReducer from './features/activities/activitiesSlice';
import accommsReducer from './features/accommodations/accommsSlice';
import tripsReducer from './features/trips/tripsSlice';
import commsReducer from './features/commission/commissionSlice';
import hotelOrdersReducer from './features/hotelorders/ordersSlice';

export const store = configureStore({
    reducer: {
        loginInfo: accountReducer,
        messages: messagesReducer,
        activities: activitiesReducer,
        accommodations: accommsReducer,
        trips: tripsReducer,
        commission: commsReducer,
        hotelOrders: hotelOrdersReducer,
    },
});
