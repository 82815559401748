import React from 'react';
import { Navigate } from 'react-router-dom';
import { GetToken } from '../utils/storage';

const PrivateRoute = ({ children }) => {
    if (!!GetToken()) {
        return children;
    }
    return <Navigate to="/login" />;
};

export default PrivateRoute;
