import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/AirSearchForm.module.css';
import styles_input from '@styles/InputContainer.module.css';
import { MdOutlineLocationOn, MdCalendarToday } from 'react-icons/md';
import Floated from '@components/Floated';
import CityPicker from '@components/CityPicker';
import { Message } from 'semantic-ui-react';
import dayjs from 'dayjs';
import { CabinClass, CabinClassName } from '@components/CabinPicker';
import { SaveToFlightSearchHistory } from '@utils/storage';
import { zhCN } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import '@styles/day-picker.css';
import { ObjectFieldMatch } from '@utils/utils';
import { MdOutlineSwapHoriz, MdOutlinePerson } from 'react-icons/md';
import { TripType } from './TripTypePicker';
import PassengerPicker from '../PassengerPicker';
import { MdKeyboardArrowDown } from 'react-icons/md';
import classIcon from '@assets/icon_cabin.svg';
import { useMyContext } from '../../contexts/MyContext';
import { Button, Dropdown } from 'antd';
import { useFlightSearchContext } from '../../contexts/FlightSearchContext';

const MAX_PASSENGER = 9;

export const defaultData = {
    tripType: TripType.Oneway,
    fromCity: '',
    fromCityCode: '',
    toCity: '',
    toCityCode: '',
    fromDate: '',
    retDate: '',
    numAdult: 1,
    numChild: 0,
    cabinPref: CabinClass.Economy,
};

function AirSearchForm({ onSubmit = () => {}, initialData = {}, initShowHistory = false }) {
    // Global state
    const { flightSearchReq: data, setFlightSearchReq: setData } = useMyContext();
    const { fetching } = useFlightSearchContext();
    // const [data, setData] = useState(defaultData);
    const [tripTypePickerActive, setTripTypePickerActive] = useState(false);
    const [depPickerActive, setDepPickerActive] = useState(false);
    const [returnPickerActive, setReturnPickerActive] = useState(false);
    const [classPickerActive, setClassPickerActive] = useState(false);
    const [passengerPickerActive, setPassengerPickerActive] = useState(false);
    const [showHistory, setShowHistory] = useState(initShowHistory);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [openRetCalendar, setOpenRetCalendar] = useState(false);
    const [advancedOpen, setAdvancedOpen] = useState(false);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (Object.keys(initialData).length != 0) {
            setData(initialData);
        }
    }, [initialData]);

    useEffect(() => {
        return () => {
            setData(defaultData);
        };
    }, []);

    const handleOnSelectFromDate = (date) => {
        if (!!date) {
            setData((prev) => {
                return { ...prev, fromDate: dayjs(date).format('YYYY-MM-DD') };
            });
        } else {
            setData((prev) => {
                return { ...prev, fromDate: '' };
            });
        }
        setOpenCalendar(false);
    };

    const handleOnSelectRetDate = (date) => {
        if (!!date) {
            setData((prev) => {
                return { ...prev, retDate: dayjs(date).format('YYYY-MM-DD') };
            });
        } else {
            setData((prev) => {
                return { ...prev, retDate: '' };
            });
        }
        setOpenRetCalendar(false);
    };

    const validate = () => {
        const errors = {};
        if (data.fromCity == '' || data.fromCityCode == '') {
            errors.fromCity = '请选择出发城市';
        }

        if (data.toCity == '' || data.toCityCode == '') {
            errors.toCity = '请选择目的地';
        }

        if (!dayjs(data.fromDate).isValid()) {
            errors.fromDate = '请选择出发日期';
        }
        if (data.tripType == TripType.RoundTrip) {
            if (!dayjs(data.retDate).isValid()) {
                errors.retDate = '请选择返回日期';
            }
            if (dayjs(data.retDate).isBefore(dayjs(data.fromDate))) {
                errors.retDate = '返回日期不能早于出发日期';
            }
        }

        if (data.numAdult < 1) {
            errors.numAdult = '至少有一个成人';
        }
        setErrors(errors);
        return errors;
    };

    const ableToSearch = () => {
        if (data.fromCity == '' || data.fromCityCode == '') {
            return false;
        }

        if (data.toCity == '' || data.toCityCode == '') {
            return false;
        }

        if (!dayjs(data.fromDate).isValid()) {
            return false;
        }
        if (data.tripType == TripType.RoundTrip) {
            if (!dayjs(data.retDate).isValid()) {
                return false;
            }
            if (dayjs(data.retDate).isBefore(dayjs(data.fromDate))) {
                return false;
            }
        }
        return true;
    };

    const handleSubmit = () => {
        const req = { ...data };
        if (Object.keys(validate()).length != 0) {
            console.log('👽');
            return;
        }
        SaveToFlightSearchHistory(ObjectFieldMatch(req, defaultData));
        onSubmit(req);
    };

    const handleSwap = () => {
        setData((prev) => {
            return {
                ...prev,
                fromCity: prev.toCity,
                fromCityCode: prev.toCityCode,
                toCity: prev.fromCity,
                toCityCode: prev.fromCityCode,
            };
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabs_container}>
                <div className={styles.tabs}>
                    <button
                        className={styles.tab_btn}
                        type="button"
                        onClick={() =>
                            setData((prev) => {
                                return { ...prev, tripType: TripType.Oneway };
                            })
                        }
                        aria-selected={data.tripType === TripType.Oneway && 'true'}
                    >
                        单程
                    </button>
                    <button
                        className={styles.tab_btn}
                        type="button"
                        onClick={() =>
                            setData((prev) => {
                                return { ...prev, tripType: TripType.RoundTrip };
                            })
                        }
                        aria-selected={data.tripType === TripType.RoundTrip && 'true'}
                    >
                        往返
                    </button>
                </div>
            </div>
            <div className={styles.content_container}>
                <div className={styles.class_container}>
                    <div className={styles.class}>
                        <div className={styles.icon_container}>
                            <img src={classIcon} />
                        </div>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        label: (
                                            <div style={{ padding: '4px 8px' }}>
                                                {CabinClassName(CabinClass.Economy)}
                                            </div>
                                        ),
                                        key: CabinClass.Economy,
                                    },
                                    {
                                        label: (
                                            <div style={{ padding: '4px 8px' }}>
                                                {CabinClassName(CabinClass.Business)}
                                            </div>
                                        ),
                                        key: CabinClass.Business,
                                    },
                                ],
                                onClick: (e) => {
                                    setData((prev) => {
                                        return { ...prev, cabinPref: e.key };
                                    });
                                },
                            }}
                            trigger={['click']}
                            arrow={true}
                        >
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', cursor: 'pointer' }}>
                                {CabinClassName(data.cabinPref)}
                                <MdKeyboardArrowDown className={styles.icon} />
                            </div>
                        </Dropdown>
                    </div>
                </div>
                {Object.keys(errors).length != 0 && <Message error list={Object.values(errors)} />}

                <div className={styles.group_container}>
                    <div className={`${styles.group_item_container} ${styles.from_container}`}>
                        <div className={styles_input.input_container}>
                            <MdOutlineLocationOn className={styles_input.icon} />
                            <input
                                className={styles_input.input}
                                name="fromCity"
                                placeholder="出发地"
                                defaultValue={`${data.fromCity}${data.fromCityCode && ` (${data.fromCityCode})`}`}
                            />
                            <button
                                name="fromCity"
                                className={styles_input.trigger_btn}
                                onClick={() => setDepPickerActive(true)}
                            />
                            <Floated active={depPickerActive} setActive={setDepPickerActive} modal={false} fluid>
                                <CityPicker
                                    active={depPickerActive}
                                    placeholder="从哪里出发？"
                                    defaultValue={data.fromCity}
                                    callback={(value) => {
                                        // console.log(value);
                                        setData((prev) => {
                                            return {
                                                ...prev,
                                                fromCity: value.cityName,
                                                fromCityCode: value.cityCode,
                                            };
                                        });
                                        setDepPickerActive(false);
                                    }}
                                />
                            </Floated>
                        </div>
                    </div>
                    {/* swap */}
                    <div className={styles.location_swap_container}>
                        <MdOutlineSwapHoriz className={styles.icon} onClick={handleSwap} />
                    </div>
                    <div className={`${styles.group_item_container} ${styles.to_container}`}>
                        <div className={styles_input.input_container}>
                            <MdOutlineLocationOn className={styles_input.icon} />
                            <input
                                className={styles_input.input}
                                name="toCity"
                                placeholder="目的地"
                                defaultValue={`${data.toCity}${data.toCityCode && ` (${data.toCityCode})`}`}
                            />
                            <button
                                name="toCity"
                                className={styles_input.trigger_btn}
                                onClick={() => setReturnPickerActive(true)}
                            />
                            <Floated active={returnPickerActive} setActive={setReturnPickerActive} modal={false} fluid>
                                <CityPicker
                                    active={returnPickerActive}
                                    placeholder="要到哪里去？"
                                    defaultValue={data.toCity}
                                    callback={(value) => {
                                        setData((prev) => {
                                            return {
                                                ...prev,
                                                toCity: value.cityName,
                                                toCityCode: value.cityCode,
                                            };
                                        });
                                        setReturnPickerActive(false);
                                    }}
                                />
                            </Floated>
                        </div>
                    </div>
                    {/* date */}
                    <div className={`${styles.group_item_container} ${styles.date_container}`}>
                        <div className={styles_input.input_container}>
                            <MdCalendarToday className={styles_input.icon} />
                            <input
                                className={styles_input.input}
                                name="fromDate"
                                placeholder="出发日期"
                                defaultValue={data.fromDate && dayjs(data.fromDate).format('YYYY-MM-DD')}
                            />
                            <button className={styles_input.trigger_btn} onClick={() => setOpenCalendar(true)} />
                            {/* NOTE: openCalendar is used to let defaultMonth take effect */}
                            {openCalendar && (
                                <Floated
                                    active={openCalendar}
                                    setActive={setOpenCalendar}
                                    modal={false}
                                    onClose={() => console.log('Float close')}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <DayPicker
                                            mode={'single'}
                                            // numberOfMonths={2}
                                            locale={zhCN}
                                            captionLayout="dropdown-buttons"
                                            fromYear={new Date().getFullYear()}
                                            toYear={new Date().getFullYear() + 2}
                                            selected={data.fromDate && new Date(data.fromDate)}
                                            onSelect={handleOnSelectFromDate}
                                            disabled={[{ from: new Date(0), to: new Date() }]}
                                            defaultMonth={data.fromDate && new Date(data.fromDate)}
                                        />
                                    </div>
                                </Floated>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${styles.group_item_container} ${styles.return_date_container}`}
                        aria-hidden={data.tripType == TripType.Oneway}
                    >
                        <div className={styles_input.input_container}>
                            <MdCalendarToday className={styles_input.icon} />
                            <input
                                className={styles_input.input}
                                name="retDate"
                                placeholder="返程日期"
                                defaultValue={data.retDate && dayjs(data.retDate).format('YYYY-MM-DD')}
                            />
                            <button className={styles_input.trigger_btn} onClick={() => setOpenRetCalendar(true)} />
                            {/* NOTE: openRetCalendar is used to let defaultMonth take effect */}
                            {openRetCalendar && (
                                <Floated active={openRetCalendar} setActive={setOpenRetCalendar} modal={false}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <DayPicker
                                            mode="single"
                                            // numberOfMonths={2}
                                            locale={zhCN}
                                            captionLayout="dropdown-buttons"
                                            fromYear={new Date().getFullYear()}
                                            toYear={new Date().getFullYear() + 2}
                                            selected={data.retDate && new Date(data.retDate)}
                                            onSelect={handleOnSelectRetDate}
                                            disabled={
                                                data.fromDate && [{ from: new Date(0), to: new Date(data.fromDate) }]
                                            }
                                            defaultMonth={data.fromDate && new Date(data.fromDate)}
                                        />
                                    </div>
                                </Floated>
                            )}
                        </div>
                    </div>

                    {/* passengers */}
                    <div className={`${styles.group_item_container} ${styles.passengers_container}`}>
                        <div className={styles_input.input_container}>
                            <MdOutlinePerson className={styles_input.icon} />
                            <input
                                className={styles_input.input}
                                name="passengers"
                                placeholder="乘客类型"
                                value={`${data.numAdult}成人 ${data.numChild}儿童`}
                                readOnly
                            />
                            <button
                                name="passengers"
                                className={styles_input.trigger_btn}
                                onClick={() => setPassengerPickerActive((prev) => !prev)}
                            />
                            <Floated
                                active={passengerPickerActive}
                                setActive={setPassengerPickerActive}
                                style={{ left: '0', top: '40px', height: 'auto' }}
                            >
                                <PassengerPicker
                                    callback={() => setPassengerPickerActive(false)}
                                    numAdult={data.numAdult}
                                    setNumAdult={(value) =>
                                        setData((prev) => {
                                            return { ...prev, numAdult: value };
                                        })
                                    }
                                    numChild={data.numChild}
                                    setNumChild={(value) =>
                                        setData((prev) => {
                                            return { ...prev, numChild: value };
                                        })
                                    }
                                    maxNum={MAX_PASSENGER}
                                />
                            </Floated>
                        </div>
                    </div>

                    <div className={`${styles.group_item_container} ${styles.search_container}`}>
                        <div className={styles.action_container}>
                            <Button type="primary" onClick={handleSubmit} disabled={!ableToSearch()} loading={fetching}>
                                查询机票
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

AirSearchForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialData: PropTypes.object,
    initShowHistory: PropTypes.bool,
};

export default AirSearchForm;
