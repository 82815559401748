import React from 'react';
import PropTypes from 'prop-types';
import { ThirdPartyDomain } from '../utils/domain';

function Footer(props) {
    const ICP = () => {
        if (window.location.host.includes(ThirdPartyDomain.Globalticket_com_cn)) {
            return (
                <a href="https://beian.miit.gov.cn/" target="_blank">
                    京ICP备2023023858号-1
                </a>
            );
        }
    };

    return (
        <footer
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                textAlign: 'center',
                fontSize: '10px',
            }}
        >
            {window.location.host.includes(ThirdPartyDomain.Globalticket_com_cn) ? (
                <>
                    Copyright © 2024 Global Booking. All Rights Reserved. 豌豆(北京)国际旅行社有限公司版权所有 <ICP />
                </>
            ) : (
                'Copyright © 2021-2024 AO-TRAVEL. All Rights Reserved'
            )}
        </footer>
    );
}

Footer.propTypes = {};

export default Footer;
