import React, { createContext, useContext, useEffect, useState } from 'react';
import { getWsManagerInstance } from '../wsManager';

/**
 * @typedef {Object} WebSocketManager
 * @property {WebSocket} ws - The WebSocket instance
 * @property {function(string): void} sendMessage - Sends a message through the WebSocket
 * @property {function(): boolean} isConnected - Checks if the WebSocket is connected
 * @property {function(function): void} addMessageListener - Adds a message listener
 * @property {function(function): void} removeMessageListener - Removes a message listener
 * @property {function(): void} disconnect - Disconnects the WebSocket
 */

/**
 * @typedef {Object} WsContextType
 * @property {WebSocketManager|null} wsManager - The WebSocket manager instance
 * @property {boolean} isConnected - Whether the WebSocket is currently connected
 */

/** @type {React.Context<WsContextType|null>} */
const WsContext = createContext(null);

/**
 * Provides the WebSocket context to its children
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function WsProvider({ children }) {
    const [wsManager, setWsManager] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const manager = getWsManagerInstance();

    useEffect(() => {
        setWsManager(manager);

        const checkConnection = () => {
            setIsConnected(manager.isConnected());
        };

        // Initial connection check
        checkConnection();

        // Set up an interval to check the connection status
        const intervalId = setInterval(checkConnection, 2000);

        // Listen for connection and disconnection events
        const handleOpen = () => setIsConnected(true);
        const handleClose = () => setIsConnected(false);

        manager.ws.addEventListener('open', handleOpen);
        manager.ws.addEventListener('close', handleClose);

        return () => {
            clearInterval(intervalId);
            manager.ws.removeEventListener('open', handleOpen);
            manager.ws.removeEventListener('close', handleClose);
            manager.disconnect();
            console.log('WebSocket disconnected because the component unmounted');
        };
    }, [manager]);

    return <WsContext.Provider value={{ wsManager, isConnected }}>{children}</WsContext.Provider>;
}

/**
 * Hook to use the WebSocket context
 * @returns {WsContextType} The WebSocket context value
 * @throws {Error} If used outside of a WsProvider
 */
export function useWs() {
    const context = useContext(WsContext);
    if (context === null) {
        throw new Error('useWs must be used within a WsProvider');
    }
    return context;
}

export default WsContext;
