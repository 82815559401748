import React, { useId, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/Floated.module.css';
import { MdOutlineClose } from 'react-icons/md';
import { DevMode, PreventBodyScroll } from '../utils/utils';

//modal: fullscreen modal in small view
//fuild: min-width: 100%
function Floated({
    active = false,
    setActive = () => {},
    onClose = () => {},
    children,
    style,
    modal = false,
    fluid = false,
    locked = false,
    ...rest
}) {
    const uniqueID = useId();
    const ref = useRef();

    //NOTE: useLayoutEffect is a version of useEffect that fires before the browser repaints the screen.
    useEffect(() => {
        const handler = (e) => {
            if (ref.current.getAttribute('locked') == 'true') return;
            // if action is on the element or its children, do nothing
            if (!ref.current.contains(e.target)) {
                // console.log('not inside');
                setActive(false);
            }
        };
        //NOTE: mousedown is different to click
        document.addEventListener('mousedown', handler);
        return () => {
            // cleanup
            // console.log('cleanup Floated');
            document.removeEventListener('mousedown', handler);
        };
    }, []);

    useEffect(() => {
        // if (!!active) {
        //     PreventBodyScroll(true);
        // } else {
        //     PreventBodyScroll(false);
        // }
        // return () => {
        //     PreventBodyScroll(false);
        // };
    }, [active]);

    useEffect(() => {
        if (!!active == false) {
            onClose();
        }
    }, [active]);

    return (
        <div
            id={uniqueID}
            ref={ref}
            className={`${styles.container} ${active && styles.active} ${fluid && styles.fluid} ${
                modal && styles.modal
            }`}
            style={style}
            locked={locked ? 'true' : 'false'}
            {...rest}
        >
            <div className={styles.close_badge} onClick={() => setActive(false)}>
                <MdOutlineClose />
            </div>
            <div className={styles.content_container}>{children}</div>
        </div>
    );
}

Floated.propTypes = {
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    modal: PropTypes.bool,
    fluid: PropTypes.bool,
    locked: PropTypes.bool,
};

export default Floated;
