import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as config from '@config';
import { addMessage, MsgLevel } from '@features/messages/messagesSlice';
import { GetToken } from '../../utils/storage';

const initialState = {
    data: [],
    fetching: false,
    fetched: false,
    error: '',
};

// fetch ALL activities
export const fetchActivities = createAsyncThunk('activities/fetch', async (searchParams, thunkAPI) => {
    const url = new URL(`${config.apiURL}/activities`);
    try {
        const resp = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${GetToken()}`,
            },
        });
        return resp.data;
    } catch (error) {
        const msg = error.response?.data?.error || 'Something went wrong with fetchActivities';
        thunkAPI.dispatch(
            addMessage({
                type: MsgLevel.Error,
                content: msg,
            })
        );
        return thunkAPI.rejectWithValue(msg);
    }
});

const activitiesSlice = createSlice({
    name: 'activities',
    initialState,
    reducers: {
        cleanupActivities: (state) => {
            console.log('cleanup activities');
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(fetchActivities.pending, (state, action) => {
                state.fetching = true;
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                const activities = action.payload.activities;
                state.fetched = true;
                state.fetching = false;
                state.data = activities;
            })
            .addCase(fetchActivities.rejected, (state, action) => {
                state = { ...initialState };
                state.error = action.payload;
                console.log(action);
            });
    },
});

export const { cleanupActivities } = activitiesSlice.actions;

export default activitiesSlice.reducer;
