import { WhiteLabelIconURL } from './storage';

export const ThirdPartyDomain = {
    Globalticket_com_cn: 'globalticket.com.cn',
};

export const IsThirdPartyDomain = () => {
    if (
        window.location.host.includes('localhost') ||
        window.location.host.includes('127.0.0.1') ||
        window.location.host.includes('aotravelconnect')
    ) {
        return false;
    }
    return true;
};

export const DynamicIcon = () => {
    let link = document.querySelector("link[rel~='icon']");
    // console.log(location.hostname);
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/x-icon';
        if (location.hostname == 'aotravelconnect.com') {
            link.href = 'https://agency.aotravelconnect.com/favicon.ico';
        } else {
            link.href = `https://agency.aotravelconnect.com/public/icon?whiteLabelDomain=${location.hostname}`;
        }
        document.head.appendChild(link);
    }
};

export const DynamicTitle = () => {
    if (window.location.host.includes(ThirdPartyDomain.Globalticket_com_cn)) {
        document.title = '豌豆(北京)国际旅行社有限公司';
    }
};
