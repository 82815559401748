import React from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/CabinPicker.module.css';

export const CabinGrade = {
    Economy: 'Y',
    Business: 'C',
    First: 'F',
};

export const CabinGradeName = (v) => {
    switch (v) {
        case CabinGrade.Economy:
            return '经济舱';
        case CabinGrade.Business:
            return '商务舱';
        case CabinGrade.First:
            return '头等舱';
        default:
            return 'Unknown';
    }
};

export const CabinClass = {
    Economy: 'E',
    PremiumEconomy: 'P',
    Business: 'B',
    First: 'F',
};

export const CabinClassName = (v) => {
    switch (v) {
        case CabinClass.Economy:
            return '经济舱';
        case CabinClass.PremiumEconomy:
            return '高级经济舱';
        case CabinClass.Business:
            return '商务舱';
        case CabinClass.First:
            return '头等舱';
        default:
            return 'Unknown';
    }
};

function CabinPicker({ callback = () => {}, cabinPref = CabinClass.Economy, setCabinPref = () => {}, newSet = false }) {
    const handleOnClick = (e) => {
        const value = e.target.getAttribute('value');
        setCabinPref(value);
        callback();
    };

    return (
        <div className={styles.container}>
            {newSet ? (
                <>
                    <div
                        className={styles.item_container}
                        aria-selected={cabinPref == CabinClass.Economy && 'true'}
                        value={CabinClass.Economy}
                        onClick={handleOnClick}
                    >
                        {CabinClassName(CabinClass.Economy)}
                    </div>
                    <div
                        className={styles.item_container}
                        aria-selected={cabinPref == CabinClass.Business && 'true'}
                        value={CabinClass.Business}
                        onClick={handleOnClick}
                    >
                        {CabinClassName(CabinClass.Business)}
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={styles.item_container}
                        aria-selected={cabinPref == CabinGrade.Economy && 'true'}
                        value={CabinGrade.Economy}
                        onClick={handleOnClick}
                    >
                        {CabinGradeName(CabinGrade.Economy)}
                    </div>
                    <div
                        className={styles.item_container}
                        aria-selected={cabinPref == CabinGrade.Business && 'true'}
                        value={CabinGrade.Business}
                        onClick={handleOnClick}
                    >
                        {CabinGradeName(CabinGrade.Business)}
                    </div>
                </>
            )}
        </div>
    );
}

CabinPicker.propTypes = {
    callback: PropTypes.func.isRequired,
    cabinPref: PropTypes.string,
    setCabinPref: PropTypes.func.isRequired,
    newSet: PropTypes.bool,
};

export default CabinPicker;
