import { createSlice } from '@reduxjs/toolkit';

export const MsgLevel = {
    Error: 'error',
    Warning: 'waring',
};

const initialState = {
    data: [],
    fetching: false,
    fetched: false,
    error: '',
};

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        addMessage: (state, action) => {
            const msg = action.payload;
            const found = state.data.find((item) => item.content == msg.content);
            if (!found) {
                state.data = [...state.data, msg];
            } else {
                console.warn('duplicate message');
            }
            state.fetched = true;
            return state;
        },
        removeMessageAt: (state, action) => {
            const i = action.payload.index;
            if (typeof i == 'number') {
                const msgs = state.data.filter((_, j) => i != j);
                state.data = msgs;
                return state;
            }
        },
        cleanupMessages: (state) => {
            console.log('cleanup message');
            state = initialState;
            return state;
        },
    },
});

export const { addMessage, removeMessageAt, cleanupMessages } = messagesSlice.actions;

export default messagesSlice.reducer;
