import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as config from '../config';
import LoginForm from '@components/LoginForm';
import { login } from '@features/account/accountSlice';
import styles from '@styles/LoginPage.module.css';
import { useLocation } from 'react-router';
import { DevMode } from '../utils/utils';
import { DynamicIcon, DynamicTitle, ThirdPartyDomain } from '../utils/domain';
import { useSearchParams } from 'react-router-dom';
import Footer from '@components/Footer';

function LoginPage() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        //if this page is opened with query
        const searchParams = new URLSearchParams(location.search);
        const v = searchParams.get('v');
        // console.log(v);
        setUsername(v);
        DynamicIcon();
        DynamicTitle();
    }, []);

    const submit = (data) => {
        return dispatch(login(data)).then((res) => {
            // console.log(res);
            return res;
        });
    };

    const title = () => {
        const params = new URLSearchParams(searchParams);
        const channel = params.get('channel');
        if (
            window.location.host.includes(ThirdPartyDomain.Globalticket_com_cn) ||
            channel == ThirdPartyDomain.Globalticket_com_cn
        ) {
            return (
                <img
                    src={`https://agency.aotravelconnect.com/public/logo?whiteLabelDomain=${ThirdPartyDomain.Globalticket_com_cn}`}
                />
            );
        }
        return 'Agent Portal';
    };

    return (
        <div className={styles.container}>
            <div className={styles.leading_container}>
                <div className={styles.image_container}>
                    <img src={`${config.baseURL}/images/login_image.png`} />
                </div>
                <div className={styles.description_container}>
                    <h3 className={styles.title}>连系人们、分享经验、创造美好回忆</h3>
                    <p>Agent Portal专为我们的旅游业合作伙伴设计，是您可以获得所有旅游 产品信息的一个平台。</p>
                    <p>在这里，您可以随时了解我们提供的所有机票，门票，酒店还有建议行程等所有信息并可以实时预订。</p>
                </div>
            </div>
            <div className={styles.trailing_container}>
                <div className={styles.logo_container}>
                    <img src={`${config.baseURL}/favicon/logo.svg`} />
                </div>
                <div className={styles.login_container}>
                    <div className={styles.title}>{title()}</div>
                    <LoginForm submit={submit} username={username} />
                    <div>
                        <p>If you are not a registered user, please contact your Sales Manager for your login.</p>
                        <p>如果您不是注册用户，请联系您的销售经理进行登录。</p>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

LoginPage.propTypes = {};

export default LoginPage;
