import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as config from '@config';
import { GetToken, RemoveToken, RemoveUserInfo, SaveToken, SaveUserInfo } from '@utils/storage';

const initialState = {
    data: {
        email: '',
        passwordHash: '',
        token: '',
    },
    fetching: false,
    fetched: false,
};

export const login = createAsyncThunk('account/login', async (credentials, thunkAPI) => {
    try {
        // console.log(thunkAPI.getState());
        // thunkAPI.dispatch();
        const resp = await axios.post(`${config.apiURL}/login`, credentials);
        return resp.data;
    } catch (error) {
        // console.log(error.response);
        return thunkAPI.rejectWithValue(error.response?.data?.error || 'Something went wrong with login');
    }
});

export const token = createAsyncThunk('account/token', async (data, thunkAPI) => {
    try {
        const resp = await axios.post(`${config.apiURL}/login/token`, { token: GetToken() });
        return resp.data;
    } catch (error) {
        // console.log(error.response);
        return thunkAPI.rejectWithValue(error.response?.data?.error || 'Something went wrong with login');
    }
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        logout: (state) => {
            state = initialState;
            if (window.mySSE) {
                console.log('🗿Logout Close EventSource');
                window.mySSE.close();
                window.mySSE = null;
            }
            if (!!GetToken()) {
                window.location.replace('/');
            }
            console.log('🗿Logout clear storage');
            localStorage.clear();
            sessionStorage.clear();
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(login.pending, (state, action) => {
                state.fetching = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                const userInfo = action.payload.userInfo;
                const token = action.payload.token;
                state.fetched = true;
                state.fetching = false;
                state.data = userInfo;
                state.data.token = token || '';
                SaveUserInfo(userInfo);
                SaveToken(token);
            })
            .addCase(login.rejected, (state, action) => {
                state = initialState;
                RemoveUserInfo();
                RemoveToken();
            })
            .addCase(token.fulfilled, (state, action) => {
                // refresh userInfo and token
                const userInfo = action.payload.userInfo;
                const token = action.payload.token ?? '';
                state.data = userInfo;
                state.data.token = token || '';
                SaveUserInfo(userInfo);
                SaveToken(token);
            });
    },
});

// console.log(accountSlice);
export const { logout } = accountSlice.actions;

export default accountSlice.reducer;
