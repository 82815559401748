import React, { createContext, useState, useContext, useEffect } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

/**
 * @typedef {Object} CartItem
 */

/**
 * @typedef {Object} CartContextType
 * @property {CartItem[]} data - The current state of the cart data
 * @property {(item: CartItem) => Promise<any>} addToCart - Function to add an item to the cart
 * @property {(itemId: string, req: object) => Promise<any>} changeQuantity - Function to change the quantity of an item in the cart
 * @property {(itemId: string) => Promise<any>} deleteItem - Function to delete an item from the cart
 * @property {() => Promise<any>} fetchCartData - Function to fetch the latest cart data
 * @property {() => Promise<any>} cartToOrder - Function to submit cart to order
 * @property {boolean} fetching - Indicates if a cart operation is in progress
 * @property {boolean} fetched - Indicates if the cart data has been fetched at least once
 * @property {boolean} deleting - Indicates if an item deletion is in progress
 * @property {boolean} submitting - Indicates if cart to order is in progress
 */

/** @type {React.Context<CartContextType>} */
const CartContext = createContext();

/**
 * Custom hook for making authenticated API requests
 * @returns {{ makeRequest: (url: string, options?: RequestInit) => Promise<any> }}
 */
const useApi = () => {
    const makeRequest = async (url, options = {}) => {
        const token = GetToken();
        try {
            const response = await fetch(url, {
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    ...options.headers,
                },
            });
            if (!response.ok) {
                const errorText = await response.text();
                let errorMessage;
                try {
                    const errorData = JSON.parse(errorText);
                    errorMessage = errorData.error || `API call failed: ${response.statusText}`;
                } catch (parseError) {
                    errorMessage = `API call failed: ${response.statusText}. Response: ${errorText}`;
                }
                throw new Error(errorMessage);
            }
            return response.json();
        } catch (error) {
            if (error instanceof Error) {
                throw error;
            } else {
                throw new Error('An unexpected error occurred during the API request');
            }
        }
    };

    return { makeRequest };
};

/**
 * Provider component for cart context
 * @param {{ children: React.ReactNode }} props
 */
export const CartProvider = ({ children }) => {
    const [data, setData] = useState({});
    const [fetching, setFetching] = useState(false); // use it to indicae changing as well
    const [fetched, setFetched] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [submitting, setSubmitting] = useState(false); // cart to order
    const { makeRequest } = useApi();

    useEffect(() => {
        fetchCartData();
    }, []);

    /**
     * Fetches the latest cart data from the API
     * @returns {Promise<any>} The fetched cart data
     * @throws {Error} If fetching fails
     */
    const fetchCartData = async () => {
        setFetching(true);
        try {
            const data = await makeRequest(`${config.apiURL}/cart`);
            // console.log('Cart data:', data);
            setData(data);
            setFetched(true);
            return data;
        } catch (error) {
            console.error('Error fetching cart data:', error);
            throw error;
        } finally {
            setFetching(false);
        }
    };

    /**
     * Adds an item to the cart
     * @param {CartItem} item - The item to add to the cart
     * @returns {Promise<any>} The response from the add to cart API
     * @throws {Error} If adding the item fails
     */
    const addToCart = async (item) => {
        setFetching(true);
        try {
            const data = await makeRequest(`${config.apiURL}/cart`, {
                method: 'POST',
                body: JSON.stringify(item),
            });
            await fetchCartData();
            return data;
        } catch (error) {
            console.error('Error adding item to cart:', error);
            throw error;
        } finally {
            setFetching(false);
        }
    };

    const changeQuantity = async (itemId, req) => {
        setFetching(true);
        try {
            const data = await makeRequest(`${config.apiURL}/cart/${itemId}`, {
                method: 'PATCH',
                body: JSON.stringify(req),
            });
            await fetchCartData();
            return data;
        } catch (error) {
            console.error('Error changing item quantity:', error);
            throw error;
        } finally {
            setFetching(false);
        }
    };

    const deleteItem = async (itemId) => {
        setDeleting(true);
        try {
            const data = await makeRequest(`${config.apiURL}/cart/${itemId}`, {
                method: 'DELETE',
            });
            await fetchCartData();
            return data;
        } catch (error) {
            console.error('Error deleting item from cart:', error);
            throw error;
        } finally {
            setDeleting(false);
        }
    };

    const cartToOrder = async () => {
        setFetching(true);
        setSubmitting(true);
        try {
            const data = await makeRequest(`${config.apiURL}/order`, {
                method: 'POST',
            });
            await fetchCartData();
            return data;
        } catch (error) {
            console.error('Error deleting item from cart:', error);
            throw error;
        } finally {
            setFetching(false);
            setSubmitting(false);
        }
    };

    /** @type {CartContextType} */
    const contextValue = {
        data,
        addToCart,
        changeQuantity,
        deleteItem,
        fetchCartData,
        cartToOrder,
        fetching,
        fetched,
        deleting,
        submitting,
    };

    return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};

export const useCart = () => useContext(CartContext);
