import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as config from '@config';
import { addMessage, MsgLevel } from '@features/messages/messagesSlice';
import { GetToken } from '../../utils/storage';

const initialState = {
    data: { defaultRate: 0, adjusted: [] },
    fetching: false,
    fetched: false,
    error: '',
};

// fetch ALL accommodations
export const fetchCommissionProfile = createAsyncThunk('commission/fetch', async (data, thunkAPI) => {
    const url = new URL(`${config.apiURL}/commission/profile`);
    try {
        const resp = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${GetToken()}`,
            },
        });
        return resp.data;
    } catch (error) {
        const msg = error.response?.data?.error || 'Something went wrong with fetchCommissionProfile';
        thunkAPI.dispatch(
            addMessage({
                type: MsgLevel.Error,
                content: msg,
            })
        );
        return thunkAPI.rejectWithValue(msg);
    }
});

const commissionSlice = createSlice({
    name: 'commission',
    initialState,
    reducers: {
        cleanupCommission: (state) => {
            console.log('cleanup commission');
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(fetchCommissionProfile.pending, (state, action) => {
                state.fetching = true;
            })
            .addCase(fetchCommissionProfile.fulfilled, (state, action) => {
                const profile = action.payload.profile;
                state.fetched = true;
                state.fetching = false;
                state.data = profile;
            })
            .addCase(fetchCommissionProfile.rejected, (state, action) => {
                state = { ...initialState };
                state.error = action.payload;
                console.log(action);
            });
    },
});

export const { cleanupCommission } = commissionSlice.actions;

export default commissionSlice.reducer;
