import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/CityPicker.module.css';
import { MdSearch, MdSearchOff } from 'react-icons/md';
import * as config from '@config';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import { GetToken } from '@utils/storage';
import ResultItem from './ResultItem';
import { AustralianCities } from './constant.js';

const resultLimit = 5;

function CityPicker({
    active = false,
    placeholder = '',
    defaultValue = '',
    callback = () => {},
    cityOnly = true,
    defaultResults = [], // default results
}) {
    const ref = useRef();

    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    const controller = new AbortController();

    useEffect(() => {
        if (active) {
            ref.current.focus();
            if (defaultValue) {
                setValue(defaultValue);
                search(defaultValue);
            }
        }
        return () => {
            if (active) {
                console.log('CityPicker closed');
                if (loading) {
                    controller.abort();
                }
                reset();
            }
        };
    }, [active, defaultValue]);

    useEffect(() => {
        if (Array.isArray(defaultResults) && defaultResults.length > 0) {
            setResults(defaultResults);
        }
    }, [defaultResults]);

    const reset = () => {
        flushResults();
        setValue(defaultValue);
    };

    const flushResults = () => {
        if (Array.isArray(defaultResults) && defaultResults.length > 0) {
            setResults(AustralianCities);
        } else {
            setResults([]);
        }
    };

    const onChange = (e) => {
        const value = e.target.value;
        // console.log(value);
        setValue(value);
        search(value);
    };

    const search = (value) => {
        if (value.length < 2) {
            flushResults();
            return;
        }
        setLoading(true);
        let limit = resultLimit;
        axios
            .get(`${config.apiURL}/city?value=${value}&limit=${limit}&cityOnly=${cityOnly}`, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                const results = res.data.results;
                // console.log(results);
                setResults(results);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOnClick = (item) => {
        callback(item);
    };

    const Results = () => {
        if (loading) {
            return <Loader inline="centered" active={true} />;
        }
        if (results.length == 0 && !!value) {
            return (
                <div className={styles.icon_container}>
                    <MdSearchOff className={styles.icon} />
                    <div className={styles.text}>无结果</div>
                </div>
            );
        }
        if (results.length == 0 && !value) {
            return (
                <div className={styles.icon_container}>
                    <MdSearch className={styles.icon} />
                    <div className={styles.text}>搜索名称/代码</div>
                </div>
            );
        }
        return results.map((item, index) => {
            return <ResultItem key={index} item={item} handleOnClick={handleOnClick} cityOnly={cityOnly} />;
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.results_container}>
                <input
                    ref={ref}
                    className={styles.input}
                    placeholder={placeholder}
                    autoFocus
                    onChange={onChange}
                    value={value}
                />
                <Results />
            </div>
        </div>
    );
}

CityPicker.propTypes = {
    active: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    callback: PropTypes.func.isRequired,
    cityOnly: PropTypes.bool,
};

export default CityPicker;
