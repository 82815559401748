import { createContext, useContext } from 'react';
import useFlightSearch from '../hooks/flight/useFlightSearch';

const FlightSearchContext = createContext(null);

export function FlightSearchProvider({ children }) {
    const { fetching, fetched, data, timestamp, fetchFlights, abort } = useFlightSearch();

    const value = {
        fetching,
        fetched,
        data,
        timestamp,
        fetchFlights,
        abort,
    };

    return <FlightSearchContext.Provider value={value}>{children}</FlightSearchContext.Provider>;
}

export const useFlightSearchContext = () => useContext(FlightSearchContext);
